import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  FullTextSearchResponseType,
  FullTextSearchRequestType,
} from '@app/interfaces/full-text-search.type';

import { baseQuery } from '../baseQuery';
export const fullTextSearchApi = createApi({
  reducerPath: 'fullTextSearchApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getFullWordSearch: build.query<FullTextSearchResponseType, Partial<FullTextSearchRequestType>>({
      query: (body) => ({
        method: 'POST',
        url: serverRoutes.full_text_search,
        body,
      }),
    }),
  }),
});

export const { useLazyGetFullWordSearchQuery } = fullTextSearchApi;
