import React, { FC } from 'react';

import { CircleButton } from '@app/components/ui';

import RubberTable from '../rubber-table';
import { TableColumn, TableItem } from '../rubber-table/table.types';

type FilterSearchTablePropsType = {
  tableData: TableItem[];
  tableCols: TableColumn[];
  handleChangePage(type: 'next' | 'prev'): void;
  offset: number;
  limit: number;
};

const FilterSearchTable: FC<FilterSearchTablePropsType> = (props) => {
  const { tableData, tableCols, handleChangePage, offset, limit } = props;
  return (
    <div className="flex flex-col items-start gap-[10px]">
      <RubberTable specialPropForSearchTable dataSource={tableData} columns={tableCols} />
      <div className="flex items-center gap-[15px]">
        <CircleButton
          disable={(offset || 0) <= 0}
          onClick={() => handleChangePage('prev')}
          size={18}
          icon="ArrowLeftIcon"
          className="text-action"
        />
        <span className="text-action text-[16px]">{offset / limit + 1}</span>
        <CircleButton
          onClick={() => handleChangePage('next')}
          size={18}
          icon="ArrowLeftIcon"
          className="rotate-180 text-action"
        />
      </div>
    </div>
  );
};

export default FilterSearchTable;
