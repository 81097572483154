import React, { FC, useState } from 'react';
import { Input, Button, Select, CircleButton } from '@app/components/ui';

import useTranslation from '@app/hooks/use-translation';

import { OptionItem } from '../../select/select.type';

export type SelectsDataType = {
  options: OptionItem[];
  index: string;
  width: number;
  defaultValue?: string;
};
type FilterSearchPropsType = {
  selects: SelectsDataType[];
  handleSubmitSearch(object: { [x: string]: string }): void;
  handleResetSearch?(): void;
};
const defaultSearchValue = { query: '' };
const FilterSearch: FC<FilterSearchPropsType> = (props) => {
  const { t } = useTranslation('components.filterSearch');
  const { selects, handleSubmitSearch, handleResetSearch } = props;
  const [searchIsVisible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<{ [x: string]: string }>(defaultSearchValue);

  function handleChangeVisibility() {
    setVisible((prev) => !prev);
  }

  function handleSubmit() {
    handleSubmitSearch(searchValue);
  }
  function onKeyDownSubmit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.code !== 'Enter') return;
    handleSubmit();
  }
  function handleReset() {
    handleResetSearch?.();
    setSearchValue(defaultSearchValue);
  }

  const searchMarkup = (
    <div
      className={`flex items-center transition-all gap-[10px] ${
        searchIsVisible ? 'visible opacity-1 w-full' : 'invisible opacity-0 w-0'
      }`}
    >
      <div className="border-[1px] text-0color border-b_dark  text-sm relative bg-white rounded-defaultR flex items-center w-full h-[36px] gap-[5px] pl-[5px]">
        {selects.map(({ index, options, width, defaultValue }, ind) => (
          <div key={ind + index} style={{ maxWidth: width + 'px', minWidth: width + 'px' }}>
            <Select
              defaultValue={searchValue?.[index] || defaultValue}
              onChange={(value) => {
                const val = value.toString();
                setSearchValue((prev) => ({ ...prev, [index]: val }));
              }}
              customClassName="!bg-4color/[.2] !h-[30px]"
              dropWidth={width + 20}
              withoutBorder
              options={options}
            />
          </div>
        ))}
        <Input
          placeholder={t('search_placeholder')}
          defaultValue={searchValue?.['query']}
          onChange={(event) => {
            const value = event.target.value.toString();
            setSearchValue((prev) => ({ ...prev, ['query']: value }));
          }}
          withoutBorder
          onKeyDown={onKeyDownSubmit}
          onClick={handleSubmit}
          icon="SearchIcon"
          iconColor="primary"
          maxLength={100}
        />
      </div>

      <div className="flex items-center">
        <CircleButton
          size={16}
          className="text-action"
          onClick={handleChangeVisibility}
          icon="EyeIcon"
        />
        <CircleButton size={12} className="text-dangerBg" onClick={handleReset} icon="XIcon" />
      </div>
    </div>
  );
  return (
    <div className="flex items-center grow">
      <div
        className={`self-end flex justify-end transition-all ${
          !searchIsVisible ? 'visible opacity-1 w-full' : 'invisible opacity-0 w-0'
        }`}
      >
        <Button fill="linked" onClick={handleChangeVisibility} label={t('full_word_search')} />
      </div>
      {searchMarkup}
    </div>
  );
};

export default FilterSearch;
